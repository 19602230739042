import { AppBar, Button, Toolbar } from "@material-ui/core";
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styles } from '../app-styles/app-styles';



interface Props extends WithStyles<typeof styles>{
  handleDrawerOpen: Function,handleDrawerClose: Function,}

interface DrawerState {
  open: boolean;
  mobileView: boolean;
  }

const headersData = [
  {
    label: 'Home',
    href: "/",
  },
  {
    label: 'About',
    href: "/about",
  },
  {
    label: 'Flooring',
    href: "/flooring",
  },
  {
    label: 'Contact',
    href: "/contact",
  }
];



class HeaderDisplayContainer extends React.Component<Props, DrawerState> {
    constructor(props: any) {
        super(props)
        this.state = {
          open : true,
          mobileView : false
        };    
    }

    handleDrawerOpen = () => { 
      this.setState({open: true})
    };
    handleDrawerClose(){ 
      console.log('Parent handleDrawerClose');
      this.setState({open: false})
    };

    getMenuButtons = () => {
      return headersData.map(({ label, href }) => {
        return (
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: RouterLink,
              className: this.props.classes.menuButton
            }}
          >
            {label}
          </Button>
        );
      });
    };




    render() {
      const { classes } = this.props;

      const handleDrawerClose = () => { 
        this.props.handleDrawerClose()
        this.setState({open: false}); 
      };

      const handleDrawerOpen = () => { 
        this.props.handleDrawerOpen()
        this.setState({open: true}); 
      };


      const gateLogo = (
        <Typography variant="h6" component="h1" className={classes.logo}>
          GATE Interiors
        </Typography>
      );


      //const { classes } = this.props;
        return(   
          <div>     
            <AppBar
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: this.state.open,
                })}
              >
                <Toolbar className={clsx(classes.toolBar)} >
                  {gateLogo}
                  <div>{this.getMenuButtons()}</div>
                  
                </Toolbar>
              </AppBar>

          </div>  
        )
    }

}

export default withStyles(styles, { withTheme: true })(HeaderDisplayContainer);
import { Container, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import * as React from "react";
import '../../App.scss';
import Box from '@material-ui/core/Box';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Link from '@material-ui/core/Link';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://gateinteriors.ie/">
        GATE Interiors
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme: Theme) => createStyles({
    greenAppBar: {
      backgroundColor: `black`,
      height: 50,
    },
    navbarDisplayFlex: {
      display: `flex`
    },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `white`
    },
    footer: {
        bottom: 90
    }
    
  });

  const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);

  const GreenTextTypography = withStyles({
    root: {
      color: "#46f07b"
    }
  })(Typography)

  const BlackTextTypography = withStyles({
    root: {
      color: "#0a0a0a"
    }
  })(Typography)
  
  
  const navLinks = [
    { title: `Fun to Find`, path: `/` },
    { title: `contact`, path: `/contact` },
  ]
  
  interface Props extends WithStyles<typeof styles>{ }


class AboutDisplayContainer extends React.Component<Props> {
    constructor(props: any) {
        super(props)
    }

    render() {
        const { classes } = this.props;

        return(
            <div>
                <Box pt={10} className="bg">
                    <Container>   
                        <BlackTextTypography variant="h2"  align="center"  >GATE Interiors </BlackTextTypography>
                        <BlackTextTypography variant="h2"  align="center"  >Specialist Flooring</BlackTextTypography>
                        <BlackTextTypography variant="h4"  align="center" >Site in development : About</BlackTextTypography>
                    </Container>                           
                </Box>  
                


                {/* Footer */}
                <Container maxWidth="md" component="footer" className={classes.footer}>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </Container>
                {/* End footer */}

            </div>
         
                



        )
    }
}

export default withStyles(styles, { withTheme: true })(AboutDisplayContainer);

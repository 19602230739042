import { Button, LinearProgress, Container, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import { Form, Formik } from "formik";
import React from 'react';
import * as yup from 'yup';
import '../../App.scss';
import { ContactService } from "../../services/contact.service";
import { MyField, MyTextArea, MyTextField } from "../MyField";
import Link from '@material-ui/core/Link';




function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://gateinteriors.ie/">
        GATE Interiors
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface Values {
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  onSubmit: (values: Values) => void;
}

const contactService = new ContactService();

const list_to = ["peter@greentor.com"];
const email_bcc = "peter@petertodd.com";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required()
    .max(10),
  messageText: yup
    .string()
    .required()
    .max(250),
  email: yup
    .string()
    .email('Invalid email')
    .required()
});

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);


const styles = (theme: Theme) => createStyles({
  greenAppBar: {
    backgroundColor: `black`,
    height: 50,
  },
  navbarDisplayFlex: {
    display: `flex`
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `white`
  },
  footer: {
      bottom: 90
  }
  
});


export const ContactDisplayContainer: React.FC<Props>  = ({onSubmit}) => {
  return (
    <Container>   
      <Box pt={4}>
        <Typography variant="h5"  align="left"  >Contact Us</Typography>
      </Box>      

      <Formik
        validateOnChange={true}
        initialValues={{ 
          firstName: "", 
          lastName: "", 
          email: "" , 
          messageText: ""
        }}

        validationSchema={validationSchema}
        
        validate={(values) => {
          const errors : Record<string, string> = {};

          if (values.firstName.includes('bob')){
            errors.firstName = 'no bob'
          }

          return errors
        }}

        onSubmit={(data, {setSubmitting,resetForm}) => {
            // alert(JSON.stringify(values, null, 2));
            // postMessage(list_to: string, email_bcc: string, email: string, messageText: string) {
            setSubmitting(true)
            contactService.postMessage(list_to,email_bcc,data.email,data.messageText, data.firstName, data.lastName).then((response: any) => {
              //alert(JSON.stringify(values, null, 2));
            console.log(response)
            setSubmitting(false)
            resetForm()        
          });
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            <Box margin={1} border={1} width={300} >
              <MyTextField
                name="firstName"
                placeholder="first name"
                type="input"
              />
              <MyTextField
                name="lastName"
                placeholder="last name"
                component={MyField}
              />
              <div>
                <Box pt={3}>
                  <MyTextArea
                    name="messageText"
                    placeholder="Type your message here (max 250 characters)"   
                    maxLength = "250"
                  />
                  {values.messageText.length > 0 ?  
                    <Typography variant="caption"  align="left" >{values.messageText.length}/250</Typography> : null
                  }
                 
                </Box>
              </div>

              <div>
                <Box pt={2}>
                  <MyTextField name="email" placeholder="email" component={MyField} />
                </Box>
              </div>

              <Box pt={2}  >
                <Typography  align="center">
                  <div>
                    <Button disabled={isSubmitting }  type="submit">submit</Button>
                  </div>
                  <div>            
                    {isSubmitting && <LinearProgress />}
                  </div>
                </Typography>
              </Box>
            </Box>
            
            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>  

            {/* Footer */}
      <Container maxWidth="md" component="footer" >
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </Container>
    

    

  )
}




export default ContactDisplayContainer;

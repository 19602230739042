import { CONTACT_ENDPOINTS } from '../constants/api.constants';
import { WEBSITE_CONSTANTS } from '../constants/website.constants';

export class ContactService {
    async postData(data = {}, overrideURL?: string) {
        const response = await fetch(
          overrideURL? overrideURL : CONTACT_ENDPOINTS.WEBFORM,
          {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: CONTACT_ENDPOINTS.TOKEN,
            ...data
          })
        });
        return response;
      }
    
      postMessage(list_to: Array<string>, email_bcc: string, email: string, messageText: string, firstName: string, lastName: string) {
        return this.postData({
            list_to: list_to,
            email_bcc: email_bcc,
            email: email,
            messageText: messageText,
            firstName: firstName,
            lastName: lastName,
            email_subject: WEBSITE_CONSTANTS.EMAIL_SUBECT,
            api: CONTACT_ENDPOINTS.WEBFORM
        })
      }
    
    }

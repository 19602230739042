import { Container, Theme, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import * as React from "react";
import '../../App.scss';
import Box from '@material-ui/core/Box';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://gateinteriors.ie/">
        GATE Interiors
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme: Theme) => createStyles({
    greenAppBar: {
      backgroundColor: `black`,
      height: 50,
    },
    navbarDisplayFlex: {
      display: `flex`
    },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `white`
    },
    footer: {
        bottom: 90
    },
    paper: {
    },
    img: {

    }
    
  });

  const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);

  const GreenTextTypography = withStyles({
    root: {
      color: "#46f07b"
    }
  })(Typography)

  const BlackTextTypography = withStyles({
    root: {
      color: "#0a0a0a"
    }
  })(Typography)
  
  
  const navLinks = [
    { title: `Fun to Find`, path: `/` },
    { title: `contact`, path: `/contact` },
  ]
  
  interface Props extends WithStyles<typeof styles>{ }


class HomeDisplayContainer extends React.Component<Props> {
    constructor(props: any) {
        super(props)
    }

    render() {
        const { classes } = this.props;

        return(
            <div>

              <div className="carousel-wrapper">
                <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} >
                    <div>
                        <img src="../images/slide1.png" />
                    </div>
                    <div>
                        <img src="../images/slide2.png" />
                    </div>
                    <div>
                        <img src="../images/slide3.png" />
                    </div>
                </Carousel>
              </div>

                <Box pt={10} className="bg">
                    <Container>   
                        <BlackTextTypography variant="h2"  align="center"  >GATE Interiors </BlackTextTypography>
                        <BlackTextTypography variant="h2"  align="center"  >Specialist Flooring</BlackTextTypography>
                        <br></br>
                        <BlackTextTypography variant="h4"  align="center" >Site in development</BlackTextTypography>
                    </Container>                           
                </Box>  

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <br></br>
                        <BlackTextTypography variant="h4"  align="center" >G.A.T.E Interiors are soft furnishing experts to the hospitality
                                      and care industry</BlackTextTypography>
                        <BlackTextTypography variant="h5"  align="center" >We supply : flame retardant curtains, blinds, bedding and soft
                                  furnishings. All tracks, poles and accessories.</BlackTextTypography>
                        <BlackTextTypography variant="h5"  align="center" >We supply : contact carpets, smooth vinyl, safety flooring, matts
                                  and bespoke carpets.</BlackTextTypography>
                        <BlackTextTypography variant="h5"  align="center" >We offer a full interior design service regardless weather you
                                  use our products or not. We specialize in contract interiors form
                                  hotels to offices.</BlackTextTypography>
                        <BlackTextTypography variant="h5"  align="center" >All of our products come with a full fitting service.</BlackTextTypography>
                      </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      
                        <Grid container spacing={1}>
                          <Grid item>
                              <img className={classes.img} alt="complex" src="images/safe_cert_logo.png" />
                          </Grid>
                          <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                              <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                We are safe cert registered.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                Our clients include leading hotels, schools, care homes, charities, public buildings to name but a few.
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                    </Paper>
                  </Grid>             
                </Grid>

                {/* Footer */}
                <Container maxWidth="md" component="footer" className={classes.footer}>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </Container>
                {/* End footer */}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(HomeDisplayContainer);

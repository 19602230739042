import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { styles } from './components/app-styles/app-styles'
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import HeaderDisplayContainer from './components/header'
import HomeDisplayContainer from './components/home';
import ContactDisplayContainer from './components/contact'
import FlooringDisplayContainer from './components/flooring'
import AboutDisplayContainer from './components/about'


import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

interface Props extends WithStyles<typeof styles>{}


class App extends React.Component<Props> {
  constructor(props: any) {
    super(props)
    this.state = {
      open : false
    };

  }

  handleDrawerOpen = () => { 
    this.setState({open: true})
  };
  handleDrawerClose(){ 
    console.log('Parent handleDrawerClose');
    this.setState({open: false})
  };

  render() {
    const { classes } = this.props;

    return (

      <Router>
        <h1>-</h1>
        <h3>-</h3>
        <HeaderDisplayContainer
        handleDrawerOpen={this.handleDrawerOpen.bind(this)}
        handleDrawerClose={this.handleDrawerClose.bind(this)}
        />
          <main className={classes.content}>            

            <Switch>
              <Route exact path="/" component={HomeDisplayContainer} />
              <Route path="/contact" component={ContactDisplayContainer} />
              <Route path="/flooring" component={FlooringDisplayContainer} />
              <Route path="/about" component={AboutDisplayContainer} />

            </Switch>
          </main>
      </Router>

    ) 
  }
}

export default withStyles(styles, { withTheme: true })(App);


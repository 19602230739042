import { Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";

const drawerWidth = 0;

export const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  links: {
    display: 'block',
    color: '#ffffff',
    textDecoration: 'none'
  },
  drawerContents: {
    padding: theme.spacing(2)
  },
  drawerSubtitle1: {
    color: '#9EA7B0'
  },
  drawerSubtitle2: {
    color: '#5B667B'
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    paddingRight: "79px",
    paddingLeft: "118px",
    backgroundColor: '#400CCC'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolBar: {
    display: 'flex',
    minHeight: 100,
    justifyContent: 'space-between',
        //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
    //alignItems: 'right',
  },
  show: {
    display: 'inherit'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#09374C'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: '#09374C'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
    marginLeft: 0

  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth
  },
});
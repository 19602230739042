import * as React from "react";
import { FieldAttributes, FieldProps, useField } from "formik";
import { FormControlLabel, TextField, Radio } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";

export const MyField: React.FC<FieldProps & TextFieldProps> = ({
  placeholder,
  field
}) => {
  return <TextField placeholder={placeholder} {...field} />;
};

type MyRadioProps ={label: string} & FieldAttributes<{}>

export const MyRadio: React.FC<MyRadioProps> = ({
    label,
    ...props
  }) => {
      const [field] = useField<{}>(props);
    return <FormControlLabel {...field} control={<Radio />} label={label} />
  };

  // Custom form Textfield that will also display errors
  // Uses meta 
export const MyTextField: React.FC<FieldAttributes<{}>> = ({placeholder, ...props}) => {
    const [field, meta] = useField<{}>(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return <TextField placeholder={placeholder} {...field} helperText={errorText} error={!!errorText} />
};

type MyTextAreaProps ={maxLength: string} & FieldAttributes<{}>

  // Custom form Textfield that will also display errors
  // Uses meta 
export const MyTextArea: React.FC<MyTextAreaProps> = ({
    maxLength,
    placeholder, 
    ...props
  }) => {
    const [field, meta] = useField<{}>(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return <TextField placeholder={placeholder} {...field} multiline={true} fullWidth={true} rows={4}  rowsMax={4} inputProps={{maxLength : maxLength}}  helperText={errorText} error={!!errorText} />
};